import { render, staticRenderFns } from "./FrontPageHead.vue?vue&type=template&id=5359f37a&scoped=true"
import script from "./FrontPageHead.vue?vue&type=script&lang=js"
export * from "./FrontPageHead.vue?vue&type=script&lang=js"
import style0 from "./FrontPageHead.vue?vue&type=style&index=0&id=5359f37a&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5359f37a",
  null
  
)

export default component.exports