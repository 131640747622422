<template>
  <div :id="app" v-cloak>
    <router-view></router-view>
    <FileUpload></FileUpload>
  </div>
</template>

<script>
import FileUpload from './onlineDesign/components/globalUploader'

export default {
  name: 'APP',
  components: {
    FileUpload
  },
  computed: {
    app() {
      return this.$store.state.app.css
    }
  },
  created() {
    if (process.env.APP_CSS) {
      this.$store.dispatch('setCSS', process.env.APP_CSS)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
@import "src/framework/styles/const.scss";

tbody {
  .textLeft {
    text-align: left !important;
  }
}

.tabelCellClass {
  text-align: center !important;
}

.el-dialog__header {
  text-align: left !important;
  border-bottom: 1px solid #ccc !important;
}

.el-submenu__title {
  font-size: $el-menu-font-size !important;
}

.el-submenu__title:hover {
  background-color: #0549b5 !important;
}

.el-menu-item {
  font-size: $el-menu-font-size !important;

  &:hover {
    color: #FFF !important;
    background-color: #0549b5 !important;
  }
}

.el-menu-item.is-active {
  //border-left: 2px solid #1dc4e6;
}

.el-menu.el-menu--popup {
  background-color: #1c6bd9;
}

.el-tooltip__popper {
  font-size: $el-tooltip-font-size !important;
}

.collapse {
  .el-collapse-item__header {
    font-size: 15px;
    font-weight: bold;
  }
}

// 取消 input 中的上下按钮
// input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
// }
// input[type="number"]{
//   -moz-appearance: textfield;
// }
#get_width_div {
  position: fixed;
  top: -10000px;
  left: -10000px;
}
</style>

