import { loginByUsername, logout, getUserInfo, outsideLogin, getPublicKey } from '@/framework/api/login'
import { fetchUserMenuTree } from '@/framework/api/user'
import { getToken, setToken, removeToken, removeCookies } from '@/framework/utils/auth'
import CryptoUtil from '@/framework/utils/CryptoUtil'
import JSEncrypt from "jsencrypt";
import i18n from '@/common_lang/index'
import { deleteDb } from '../../SZGZ/views/dataVerification/utils/indexDB'
const user = {
    state: {
        user: '',
        status: '',
        token: getToken(),
        name: '',
        roles: [],
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_USER: (state, user) => {
            state.user = user
        }
    },

    actions: {
        // 用户名登录
        LoginByUsername({ commit }, userInfo) {
            const user = {
                ...userInfo
            }
            user.username = userInfo.username.trim()
            user.client = 'redisLogin'
            user.password = CryptoUtil.encodeAes(userInfo.password)
            return new Promise((resolve, reject) => {
                loginByUsername(user).then(data => {
                    if (data.access_token) {
                        commit('SET_TOKEN', data.access_token)
                        setToken(data.access_token)
                        resolve(data)
                    } else {
                        resolve(data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 外网登录
        outSideLogin({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                // 获取公钥
                getPublicKey().then(({ code, data }) => {
                    if (code == 0 && data) {
                        const params = {
                            ...userInfo
                        }
                        params.username = userInfo.username.trim()
                        params.client = userInfo.client || 'frontend'
                        // 使用公钥进行RSA加密
                        let jsencrypt = new JSEncrypt();
                        jsencrypt.setPublicKey(data.publicKey);
                        let rsaPassword = jsencrypt.encrypt(userInfo.password);
                        params.password = rsaPassword;
                        params.secretCode = data.code;
                        params.loginUserType = 'sinopec';
                        params.grant_type = 'password';
                        params.authType = 'sinopec-oauth2';
                        outsideLogin(params).then(res => {
                            console.log("res", res);
                            if (res.access_token) {
                                commit('SET_TOKEN', res.access_token)
                                setToken(res.access_token)
                                resolve(res)
                            } else {
                                resolve(res)
                            }
                        }).catch(error => {
                            reject(error)
                        })
                    }
                });
            })
        },

        // 获取用户信息
        GetUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(response => {
                    if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
                        reject('error')
                    }
                    const data = response.data
                    const roles = []
                    data.roles.forEach(element => {
                        roles.push(element.code)
                    })
                    commit('SET_ROLES', roles)
                    commit('SET_USER', data)
                    commit('SET_NAME', data.name)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 拉起菜单
        LoadUserMenuTree({ commit }, menuTree) {
            return new Promise((resolve, reject) => {
                fetchUserMenuTree(null).then(({ data }) => {
                    let zhlang = i18n.getLocaleMessage('zh')
                    let enlang = i18n.getLocaleMessage('en')
                    let rzhlang = langF(data, { route: {} }, 'title')
                    let renlang = langF(data, { route: {} }, 'titleEn')
                    i18n.setLocaleMessage('zh', Object.assign(zhlang, rzhlang))
                    i18n.setLocaleMessage('en', Object.assign(enlang, renlang))
                    window._USERMENUTREE = data
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                deleteDb()
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                }).catch(error => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                })
                removeToken()
                removeCookies()
                resolve()
            })
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise(resolve => {
                deleteDb()
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        },

        // 动态修改权限
        // ChangeRoles({ commit }, role) {
        //     return new Promise(resolve => {
        //         commit('SET_TOKEN', role)
        //         setToken(role)
        //         getUserInfo(role).then(response => {
        //             const data = response.data
        //             commit('SET_ROLES', data.roles)
        //             commit('SET_NAME', data.name)
        //             commit('SET_AVATAR', data.avatar)
        //             commit('SET_INTRODUCTION', data.introduction)
        //             resolve()
        //         })
        //     })
        // }
    }
}

export default user

function langF(a, lang, key) {
    for (let i = 0; i < a.length; i++) {
        // if(a[i].name === 'GisShow') debugger
        lang.route[a[i].name] = a[i][key] || a[i].title
        if (a[i].children !== null) {
            langF(a[i].children, lang, key)
        }
    }
    return lang
}